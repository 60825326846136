.snapChart-wrapper {
  .vis-time-axis {
    .vis-grid.vis-vertical {
      border-left: 0.5px solid #dfe3e6 !important;
    }
  }
  .vis-timeline {
    border-width: 0;
    border-color: #dfe3e6;
    .vis-horizontal {
      border-bottom: 0.5px solid #dfe3e6;
    }
    .vis-time-axis {
      .vis-text {
        color: #152934;
        &.vis-major {
          font-weight: bold;
        }
      }      
    }
  }
  .vis-panel.vis-bottom,
  .vis-panel.vis-center,
  .vis-panel.vis-top {
    border: 0;
  }
  .vis-item {
    &:not(.vis-cluster) {
      .vis-item-content {
        padding: 0px 6px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 2px solid transparent;
        border-radius: 50%;
        color: transparent;
        font-weight: bold;
        cursor: pointer;
      }
      &.red .vis-item-content {
        border-color: rgb(224, 24, 45);
        color: rgb(224, 24, 45);
      }
      &.yellow .vis-item-content {
        border-color: rgb(239, 193, 0);
        color: rgb(239, 193, 0);
      }
      &.selected .vis-item-content {
        color: white;
      }
      &.selected.red .vis-item-content {
        background-color: rgb(224, 24, 45);
      }
      &.selected.yellow .vis-item-content {
        background-color: rgb(239, 193, 0);
      }
    }
    &.vis-cluster {
      background-color: transparent;
      border-color: transparent;
      .vis-item-content {
        top: 5px;
        width: 25px;
        height: 25px;
        line-height: 25px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 12px;
        font-weight: bold;
        border: 2px solid rgb(224, 24, 45);
        background-color: rgb(224, 24, 45);
      }
    }
    
    .vis-dot {
      display: none;
    }
    [title] {
      color: #fff;
      height: 25px;
      line-height: 25px;
    }
  }
}
/*
.snapChart-wrapper {
  display: flex;
  width: 100%;
  height: 150px;
  position: relative;
}
.snapChart {
  display: inline-flex; min-width: 100%;
}
.snapChart-section {
  position: relative;
  width: 100px; height: 130px;
  flex: 1;
  img.bgImage {
    width: 100%; height: 130px;
  }
  .year-label {
    position: absolute;
    left: 10px; top: 15px;
    font-size: 0.8em; font-weight: bold;
  }
  .snap-icon {
    border: 1.5px solid;
    width: 1.2rem; height: 1.2rem;
    border-radius: 0.75rem;
    display: inline-flex;
    justify-content: center; align-items: center;
    font-size: 0.75rem; font-weight: 600; line-height: 0.75rem;
    position: absolute; left: 0; top: 85px;
    background-color: #ffffff;
    z-index: 10;
    &.red {
      color: rgb(224, 24, 45);
      border-color: rgb(224, 24, 45);
    }
    &.yellow {
      color: rgb(239, 193, 0);
      border-color: rgb(239, 193, 0);
    }
  }
}
*/
