.image-editor-wrapper {
  width: 100%;
  height: 93%;
  position: relative;

  .image-save-icon {
    position: absolute;
    color: white;
    font-size: 20px;
    bottom: 8px;
    right: 15px;
    z-index: 1000;
  }

  .image-close-icon {
    position: absolute;
    font-size: 20px;
    top: 0px;
    right: 0px;
    z-index: 1000;
    background: white;
    padding: 5px;
    border: 2px solid lightgray;
    border-radius: 20px;
    color: #000;
  }
  .tui-image-editor-container  {
    background-color: #f4f7fb;
    .tui-image-editor-controls { 
      position: absolute;
      background-color: #000 !important;
      width: 100%;
      height: 40px;
      display: table;
      bottom: 0;
      z-index: 2;
      
      .tie-btn-undo.tui-image-editor-item.help,
      .tie-btn-redo.tui-image-editor-item.help,
      .tie-btn-reset.tui-image-editor-item.help,
      .tui-image-editor-icpartition,
      .tie-btn-delete.tui-image-editor-item.help,
      .tie-btn-deleteAll.tui-image-editor-item.help {
        display: none;
      }
    }
    
    .tui-image-editor-submenu {
      z-index: 1;
      height: 100px !important;

      .tui-image-editor-menu-crop {
        display: none !important;
      }

      .tui-image-editor-menu-draw {
        display: none !important;
      }

      .tui-image-editor-menu-text {
        display: none !important;
      }
    }
  
    .tui-image-editor-main-container {
      .tui-image-editor-align-wrap {
        .tui-image-editor {
          min-width: 60vw !important;
          min-height: 59vh !important;
          
          .tui-image-editor-canvas-container {
            max-width: 100% !important;
            max-height: 100% !important;
  
            .lower-canvas {
              max-width: 100% !important;
              max-height: 100% !important;
            }
  
            .upper-canvas {
              max-width: 100% !important;
              max-height: 100% !important;
            }
          }
        }
      }
    }
    .tui-image-editor-main {
      top: 10px !important;
    }
  }
  .tui-image-editor-main {
    width: 100%!important;
    height: calc(100% + 14px) !important;
  }

  .tui-image-editor-help-menu {
    display:none;
  }

  .tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    padding-bottom: 5px !important;
  }
  .tui-image-editor-container .tui-image-editor-range-value { 
    margin-top: 0px;
  }

  .tui-image-editor {
    top: 0px !important
  }
}
