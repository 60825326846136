.layout-grid {
  display: grid;
}

header {
  background-color: black !important;
}

.MuiDrawer-paper {
  position: relative !important;
  background-color: #323232 !important;
  color: white !important;
}
.size-drawer-dicom {
  position: absolute !important;
  top: 0;
  height: 100%;
}
.size-dicom-view {
  position: relative !important;
  height: calc(100vh - 357px);
  max-width: calc(100vh);
  width: 100%;
}
/* .cornerstone-canvas {
  height: 100% !important;
}

@media (min-width: 1024px) {
  .cornerstone-canvas {
    width: 100% !important;
  }
}

@media (min-width: 1920px) {
  .cornerstone-canvas {
    width: 100% !important;
  }
}

@media (min-width: 2580px) {
  .cornerstone-canvas {
    width: calc(100vh + 340px) !important;
  }
} */
