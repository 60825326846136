.hpiSnapList {
  .snapItem {
    display: flex;
    margin-bottom: 20px;
    .snapItem__status {      
      .snapIcon {
        border: 2px solid transparent;
        width: 1.5rem; height: 1.5rem;
        border-radius: 0.875rem;
        display: inline-flex;
        justify-content: center; align-items: center;
        font-size: 0.875rem; font-weight: 600; line-height: 0.875rem;
        &.yellow {
          color: rgb(239, 193, 0);
          border-color: rgb(239, 193, 0);
        }
        &.red {
          color: rgb(224, 24, 45);
          border-color: rgb(224, 24, 45);
        }
      }
    }
    .snapItem__content {
      margin-left: 20px;
      flex: 1;
      .snapDetail__titlebar {
        display: flex; justify-content: space-between;
        margin-bottom: 12px;
        .snapDetail__title {
          font-weight: bold;
          margin-right: 20px;          
        }
      }
    }
    
  }

}
