.review-icd-code {
  .tableItem {
    padding: 0 1rem !important;
    border: 1px solid #dfe3e6;
    &:hover {
      background-color: #dbe3ee;
    }
    &.active {
      background-color: #dbe3ee !important;
      border: 2px solid #dbe3ee !important;
    }
  }
  .tableTitle {
    width: 100%;
    height: 2.5rem;
    border: 1px solid;
    color: #152935;
    border: 1px solid #dfe3e6;
    background-color: #f4f7fb;
    font-weight: 600;
    padding: 0 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}