.questionInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &__filter {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    &-search {
      flex: 1;
      border: 1px solid #dfe3e6;
      background-color: #f4f7fb;
      border-bottom: 0;
      border-right: 0;

      .ant-input-search.ant-input-affix-wrapper {
        display: flex;

        input {
          font-size: .875rem;
          height: 2.5rem;
          border: none !important;
          border-radius: 0;
          box-shadow: none !important;
          outline: none;
          background-color: #f4f7fb;
          color: #152935;
          font-weight: 400;
          padding: 0 2.5rem;
          text-overflow: ellipsis;
          width: 100%;
          order: 1;
          flex: 1;

          &::placeholder {
            color: #5a6872d1;
            font-weight: 400;
          }
        }

        .ant-input-suffix {
          left: .75rem;
          right: unset;
          order: -1;
          flex: 0;

          i {
            display: none;
          }

          svg {
            fill: #5a6872d1;
            height: 20px;
            width: 20px;
            margin-left: 0;
          }
        }
      }

      &.disable-icon {
        .ant-input-search.ant-input-affix-wrapper input {
          padding: 0 1rem;
        }
      }
    }
    &-action {
      height: calc(2.5rem + 2px);
      width: 120px;
      background-color: #005ef6;
      padding: 0 12px;

      @media (max-width: 768px) {
        width: 90px;
      }
    }
    &-add {
      padding: 0;
      margin: 0;
      width: 100%;
      background-color: #005ef6;
      color: #ffffff;
      font-size: .875rem;
      font-weight: 600;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-decoration: none;
      outline: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }
    &-result {
      position: absolute;
      z-index: 12;
      left: 0;
      top: 0;
      height: calc(100% - 42px);
      transform: translateY(42px);
      width: calc(100% - 120px) !important;
      border: 1px solid #dfe3e6;
      max-width: 560px;

      &-list {
        position: relative;
        background-color: #ffffff;
        height: 100% !important;
        border-bottom: 0 !important;
      }
      .atuoComplete__result-item {
        border: 0 !important;
      }
    }
  }

  &__result,
  &__filter-result {
    width: 100%;

    &-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #dfe3e6;
      overflow-x: hidden;
      overflow-y: hidden;
      min-height: calc(10rem + 4px);
    }
    &-item {
      width: 100%;
      min-height: 2.5rem;
      height: 2.5rem;
      border: 1px solid #dfe3e6;
      border-bottom: 0;
      background: #ffffff;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 12px;
      color: #152935;

      p {
        font-size: 0.875rem;
        color: #152935;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .closeMessage svg {
        margin-left: 0;
        width: 16px;
      }

      svg {
        fill: rgb(90, 104, 114);
        width: 10px;
      }

      &:hover, &.active {
        background-color: #dbe3ee;
        border: 1px solid #dbe3ee;
        border-bottom: 1px solid #dbe3ee;

        & + .atuoComplete__result-item {
          border-top: 0px;
        }
      }
      
    }
  }
}
