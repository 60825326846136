.review-text {
  width: 100%; height: 95%;
  // border: 1px solid #dfe3e6;
  // padding: 10px; overflow-y: auto;
  .paragraph {
    margin-bottom: 10px;
    .p-title {
      font-size: 14px; text-decoration: underline;
      font-weight: bold;
      margin-top: 10px; margin-bottom: 10px;
    }
    .p-subtitle {
      text-decoration: underline;
    }
  }
  p {
    font-size: 14px;
  }
}

.rdw-editor-wrapper {
  height: calc(100% - 40px);
}
.public-DraftStyleDefault-block {
  margin-top: 0; margin-bottom: 0;
}
.public-DraftEditor-content h4 .public-DraftStyleDefault-block {
  margin-top: 20px; margin-bottom: 20px;
}
