$red: rgb(231, 80, 90);

.disease-icon {
  .diseaseIcons > span {
    display: none;
  }  
  &.carotid_disease {
    .diseaseIcons > span.cartoid {
      display: block;
    }
    &-clicked {
      .c-cartoid {
        stroke: $red;
      }
    }
  }
  &.aortic_aneurysm_disease {
    .diseaseIcons > span.aortic {
      display: block;
    }
    &-clicked {
      .c-aortic {
          stroke: $red;
      }
    }
  }
  &.renal_artery_stenosis {
    .diseaseIcons > span.management {
      display: block;
    }
    &-clicked {
      .a-management {
        stroke: $red;
      }
    }
  }
  &.mesenteric_disease {
    .diseaseIcons > span.mesenteric {
      display: block;
    }
    &-clicked {
      .b-ischemia {
        stroke: $red;
      }
    }
  }
  &.venous_disease {
    .diseaseIcons > span.venous {
      display: block;
    }
    &-clicked {
      .c-insufficiency {
        stroke: $red;
      }
    }
  }
  &.peripheral_artery_disease {
    .diseaseIcons > span.peripheral {
      display: block;
    }
    &-clicked {
      .b-peripheral {
        stroke: $red;
      }
    }
  }
  &.deep_venous_thrombosis_dvt {
    .diseaseIcons > span.deep {
      display: block;
    }
    &-clicked {
      .c-venous {
        stroke: $red;
      }
    }
  }  
}
