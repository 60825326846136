.calendarContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .rbc-current-time-indicator {
    background-color: #0f62fe;
    color: #0f62fe;
    height: 2px;
  }

  .rbc-calendar {
    // margin-top: 20px;
    // height: calc(100% - 45px);

    .rbc-day-slot.rbc-time-column {
      .rbc-event {
        border: none;
      }

      .rbc-events-container {
        margin-right: 0px !important;

        .rbc-event {
          background: #fff;
          border-color: #dfe3e6 !important;
          border-top: 1px solid #dfe3e6 !important;
          border-radius: 0;
          padding: 0;

          .rbc-event-content {
            width: 100%;
            height: 100%;
            background: #edf5ff;
            padding: 3px 5px;

            p {
              white-space: nowrap;
            }
          }

          &.cancelled {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #697077;
              border-left: 5px solid #DDE1E6;
            }

            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.scheduled {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #864DF8;
              border-left: 5px solid #ba8ff7;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.require-prior-authorization {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #d02670;
              border-left: 5px solid #fa8cbc;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.rescheduled {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #510224;
              border-left: 5px solid #9F1853;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.no-answer {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #878d96;
              border-left: 5px solid #c1c7cd;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.confirmed {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #198038;
              border-left: 5px solid #6fdc8c;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.checked-in {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #007D79;
              border-left: 5px solid #009D9A;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.billed {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #003A60;
              border-left: 5px solid #0072C3;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.data_saved {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #E6E428;
              border-left: 5px solid #b4e051;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }

          &.pink {
            .rbc-event-content {
              background: #edf5ff;
            }
          }

          &.grey {
            .rbc-event-content {
              background: #defbe6;
            }
          }

          &.purple {
            &.more-event-status {
              // padding-left: 1px;
            }
            .rbc-event-content {
              background: #9855d4;
              border-left: 5px solid #ba8ff7;
            }
            &.rbc-selected {
              .rbc-event-content {
                background: #005ef6;
                border-left: 5px solid #6ba4ff;
              }
            }
          }
          &.white {
            .rbc-event-content {
              background: #ffffff;
            }
          }

          &.rbc-selected {
            // z-index: 2;
          }

          .rbc-event-label {
            display: none;
          }
        }
      }
    }

    .rbc-toolbar {
      justify-content: space-between;
      align-items: flex-end;
      margin-bottom: 0;

      .rbc-btn-group {
        flex-grow: 0;
        display: inline-flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
      .rbc-btn-group button {
        // color: rgb(90, 104, 114);
        color: #152935;
        background: transparent;
        border: none;
        // border-bottom: 3px solid rgb(223, 227, 230);
        // margin-right: 0.125rem;
        margin: 0;
        max-width: 10rem;
        // width: calc(100% / 3 - 0.125rem);
        width: auto;
        border-radius: 0;
        font-weight: 400;
        font-size: 0.875rem;
        padding: 0 1rem;
        text-align: center;
        cursor: pointer;
        outline: none;
        box-shadow: none;
        // transition: border 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
        transition: color 70ms cubic-bezier(0.2, 0, 0.38, 0.9);

        &.rbc-active {
          color: #0f62fe;
          font-weight: 600;
          background: transparent;
          box-shadow: none;
          // border-bottom: 3px solid rgb(61, 112, 178);
        }

        &:nth-child(2) {
          border-right: 2px solid #0f62fe;
          border-left: 2px solid #0f62fe;
        }
        &:hover {
          color: #0f62fe;
        }
      }

      .rbc-toolbar-label {
        display: inline-block;
        box-sizing: border-box;
        flex-grow: 0;
        font-weight: 700;
        color: #152935;
      }

      .rbc-btn-group:first-child {
        display: none;
      }

      @media (max-width: 767px) {
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: nowrap;

        .rbc-toolbar-filter {
          margin-bottom: 10px;
          width: 100%;
          align-self: flex-start;
        }
        .rbc-btn-group {
          align-self: flex-end;
        }
      }
    }

    .rbc-header {
      background-color: #ffffff;
      border-color: #dfe3e6 !important;
    }

    .rbc-day-bg {
      background-color: #ffffff;
      border-color: #dfe3e6 !important;

      &.rbc-off-range-bg {
        background-color: #f4f7fb;
      }
      &.rbc-today {
        background-color: #eafbf6;
      }
    }

    .rbc-date-cell {
      font-size: 0.875rem;
    }

    // .rbc-date-cell.rbc-current::after {
    //   content: "";
    //   position: absolute;
    //   background: #ffa5b4;
    //   z-index: -1;
    //   height: 100%;
    //   width: 14.2857%;
    //   margin-left: calc(-14.2857% + 8px); /* I had an 8px offset */
    //   margin-top: -8px; /* I had an 8px offset */
    // }
  }

  .rbc-time-slot {
    font-size: 0.875rem;
    border-color: #dfe3e6 !important;
  }

  .rbc-time-view,
  .rbc-agenda-view {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .rbc-month-row {
    & + .rbc-month-row {
      // border-top: 1px solid #fff;
    }

    .rbc-row-segment .rbc-event {
      padding: 50%;
    }
    .rbc-row-segment {
      padding: 0;
    }
    .rbc-day-bg.rbc-off-range-bg,
    .rbc-day-bg + .rbc-day-bg {
      background: transparent;
      z-index: 20;
      // border-left: 1px solid #fff;
    }
    .rbc-day-bg.rbc-today {
      outline: 2px solid #0f62fe;
      outline-offset: -2px;
      background: transparent;
      z-index: 20;
    }
  }
  .rbc-month-view {
    flex: 1;
    height: auto;
    border-color: #dfe3e6 !important;

    .rbc-event {
      background-color: transparent !important;
      border-radius: 0 !important;
      position: relative;

      .rbc-event-content {
        background: transparent;
      }

      &.pink {
        background-color: #edf5ff !important;

        &::before {
          content: "";
          position: absolute;
          background: #edf5ff;
          z-index: 5;
          height: 100%;
          width: 100%;
          top: -24px;
          left: 0;
        }

        .rbc-event-content {
          background: #edf5ff;
        }
      }

      &.grey {
        background-color: #defbe6 !important;

        &::before {
          content: "";
          position: absolute;
          background: #defbe6;
          z-index: 5;
          height: 100%;
          width: 100%;
          top: -24px;
          left: 0;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background: transparent;
        z-index: 5;
        height: 100%;
        width: 100%;
        top: -24px;
        left: 0;
      }
    }

    .rbc-row-content {
      flex: 1;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;

      .rbc-date-cell {
        background-color: transparent;
      }

      .rbc-row:nth-child(2) {
        flex: 1;
        height: 100%;

        .rbc-event {
          height: 100%;
          background-color: transparent;

          .rbc-event-content {
            height: 100%;
            position: absolute;
            top: -21px;
            left: 1px;
            width: 100%;
            z-index: 10;
            border: 0px solid #fff;
          }
        }
      }
    }
  }
  .rbc-time-view {
    background: #ffffff;

    .rbc-time-content {
      border-color: #dfe3e6 !important;
      border-top: 1px solid;
    }
    .rbc-time-header {
      border-color: #dfe3e6 !important;
      border-bottom: 1px solid #dfe3e6 !important;
    }

    .rbc-label.rbc-time-header-gutter {
      background: #ffffff;

      & + .rbc-time-header-content {
        & + .rbc-time-header-content {
          margin-left: 0px !important;
        }
      }
    }
    .rbc-time-header-content {
      border-color: #dfe3e6 !important;
      border-bottom: 0px !important;

      .rbc-allday-cell {
        display: none;
      }
      .rbc-row.rbc-time-header-cell {
        .rbc-header {
          border-color: #dfe3e6 !important;
          border-bottom: none;
        }
      }
    }

    .rbc-timeslot-group {
      background: #ffffff;
      border-bottom: 0px;
      border-color: #dfe3e6 !important;
    }

    .rbc-day-slot {
      .rbc-timeslot-group:first-child {
        .rbc-time-slot {
          border-top: 0px;
        }
      }
    }
  }
  .rbc-header {
    height: 2.5rem;
    font-size: 0.875rem;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rbc-event-content {
    font-size: 0.875rem;
    font-weight: 400;
  }

  .rbc-toolbar-filter {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 10px;
    margin-top: 0;
    margin: 10px 0;

    .rbc-dropdown-Filter {
      display: inline-block;
      width: 50%;
      min-width: 180px;
      max-width: 210px;
      margin: 0;
      margin-right: 10px;
      font-size: 16px;
      text-align: left;

      .ant-select {
        position: relative;
        width: 100%;
        height: 2.5rem;
        max-height: 2.5rem;
        background-color: #ffffff;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid rgb(141, 141, 141);
        cursor: pointer;
        color: #152935;

        &.ant-select-open {
          .ant-select-arrow {
            svg {
              fill: rgb(21, 21, 21);
              transform: rotate(180deg);
            }
          }
        }
      }

      .ant-select-selection.ant-select-selection--single {
        font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        letter-spacing: 0;
        background: none;
        appearance: none;
        border: 0;
        width: 100%;
        position: relative;
        height: 100%;
        cursor: pointer;
        font-size: 0.875rem;
        display: block;
        width: 100%;
        height: 2.5rem;
        padding: 0 1rem;
        color: #152935;
        box-shadow: none;
        order: 2;
        border: none;
        border-bottom: none;
        border-radius: 0;

        .ant-select-selection__rendered {
          width: 100%;
          margin: 0;
          height: 100%;
          display: flex;
          align-items: center;
        }
        .ant-select-arrow {
          flex: 0;

          svg {
            fill: rgb(21, 21, 21);
            transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
          }
        }
        .ant-select-selection__placeholder {
          color: #5a6872d1;
        }
      }
    }

    @media (max-width: 991px) {
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-left: 0;
    }
    @media (max-width: 498px) {
      .rbc-dropdown-Filter {
        width: 100%;
        max-width: 100%;
        margin: 0;

        &:first-child,
        &:nth-child(2) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .ant-calendar-picker {
    position: relative;
    box-shadow: none;
    border-bottom: 1px solid rgb(141, 141, 141);

    svg {
      position: absolute;
      right: 11px;
      top: 50%;
      transform-origin: 50% 50%;
      fill: rgb(21, 21, 21);
      transition: transform 0.2s cubic-bezier(0.5, 0, 0.1, 1);
    }

    .anticon.anticon-close-circle.ant-calendar-picker-clear {
      display: none;
      right: 30px;
      top: 50%;
      transform-origin: 50% 50%;

      svg {
        position: unset;
        transform-origin: unset;
      }
    }

    .ant-calendar-range-picker-separator {
      display: none;
    }
  }

  .ant-calendar-picker-input.ant-input {
    font-family: "IBM plex sans", "Helvetica Neue", sans-serif;
    font-size: 0.875rem;
    height: 2.5rem;
    border: none !important;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    color: #152935;
    font-weight: 400;
    padding: 0 2.5rem 0 1rem;
    text-overflow: ellipsis;
    width: 100%;

    &::placeholder {
      color: #5a6872d1;
      font-weight: 400;
    }
  }

  @media (max-height: 834px) and (max-width: 1112px) {
    height: 50%;
  }
}

.ant-calendar-picker .ant-calendar-range-picker-separator {
  display: none;
}

.ant-calendar-picker {
  .ant-calendar-range-picker-input {
    box-shadow: none !important;
  }
  input::placeholder {
    color: #5a6872d1;
    font-weight: 400;
  }
}

.rbc-event-content p {
  margin-bottom: 0 !important;
  color: #fff;
}

.rbc-month-row .rbc-date-cell {
  position: relative;

  & > a {
    position: absolute;
    z-index: 20;
    right: 6px;
  }
}

.rbc-date-cell.rbc-now {
  color: #0f62fe;
}

.rbc-event.pink {
  cursor: default !important;
  width: 100% !important;
}